<template>
  <div class="calendar-area" :class="classObject">
    <div class="calendar" >
      <div class="week" v-if="days">
        <div class="day">SUN</div>
        <div class="day">MON</div>
        <div class="day">TUE</div>
        <div class="day">WED</div>
        <div class="day">THU</div>
        <div class="day">FRI</div>
        <div class="day">SAT</div>
      </div>
      <div class="date">
        <div
          class="number prev"
          v-for="(i, index) in lastMonthDays()"
          :key="'a'+index"
          > {{ i }} </div>
        
        <div
          class="number"
          v-for="i in lastDayInMonth()"
          :key="i"
          :class="currentDateClass(i)"
          @click="$emit('on-click', i)"
        >
          {{ i }}
          <slot :day="i"></slot>
        </div>
        <div
          class="number next"
          v-for="(i, index) in nextMonthDays()"
          :key="'b'+index"
        >{{ i }}</div> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classObject: {
      type: String,
      default: () => "large",
    },
    days: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currentDate: new Date().toLocaleString('default', { day: 'numeric'}),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    lastDayInMonth() {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    },
    lastMonthDays() {
      let prevDays = [];
      let prevMonthEndDya = new Date(this.currentYear, this.currentMonth, 0).getDate();
      for(let i = this.monthStartDay(); i > 0; i--) {
        let res = prevMonthEndDya--;
        prevDays.unshift(res);
      }
      return prevDays;
    },
    nextMonthDays() {
      let totalCell = this.lastMonthDays().length + this.lastDayInMonth();
      let remainDay = (totalCell % 7);
      return remainDay == 0 ? remainDay : 7 - remainDay 
    },
    monthStartDay() {
      return new Date(this.currentYear, this.currentMonth, 1).getDay();
    },
    currentDateClass(i) {
      const calendarFullDate = new Date(this.currentYear, this.currentMonth, i).toDateString();
      const currentFullDate = new Date().toDateString();
      return (calendarFullDate === currentFullDate) ? 'active' : '';
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/main";


/*
  Common CSS for large and mini
*/

.calendar .date .number {
  border: 1px solid #6f7070;
  position: relative;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s;
  @include center();
  &:hover, &.active {
    background: $victoria-color;
    color: #fff;
    cursor: pointer;
  }
}

.calendar .date .prev,
.calendar .date .next {
  opacity: 0.3;
  pointer-events: none;
}


/*
  Large calendar CSS only
*/
.calendar-area.large {
  position: relative;
  width: 100%;
  padding-top: 50%;  /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
}

.large .calendar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.large .date {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 100%;
  grid-gap: 15px;
  .number {
    font-size: 3rem;
    @include below(small) {
      font-size: 2rem;
    }
    @include below(xsmall) {
      font-size: 1rem;
    }

  }
}


/*
  Mini calendar CSS only
*/

.mini .date,
.mini .week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  margin: 10px 0;
}

.mini .week {
  margin-bottom: 5px;
  .day {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
}
.mini .date {
  @include above(medium) {
    justify-items: center;
  }
}
.mini .number {
  font-size: 0.85rem;
  @include above(medium) {
    width: 55px;
  }
  height: 45px;
}

</style>
